import React, { useEffect } from 'react';

const keyPositions = {
    // Numbers row
    '`': 0.05, '1': 0.1, '2': 0.2, '3': 0.3, '4': 0.4, '5': 0.5, '6': 0.6, '7': 0.7, '8': 0.8, '9': 0.9, '0': 1.0, '-': 1.1, '=': 1.2,

    // Top row
    'Q': 0.15, 'W': 0.25, 'E': 0.35, 'R': 0.45, 'T': 0.55, 'Y': 0.65, 'U': 0.75, 'I': 0.85, 'O': 0.95, 'P': 1.05, '[': 1.15, ']': 1.25, '\\': 1.35,

    // Home row
    'A': 0.2, 'S': 0.3, 'D': 0.4, 'F': 0.5, 'G': 0.6, 'H': 0.7, 'J': 0.8, 'K': 0.9, 'L': 1.0, ';': 1.1, "'": 1.2,

    // Bottom row
    'Z': 0.25, 'X': 0.35, 'C': 0.45, 'V': 0.55, 'B': 0.65, 'N': 0.75, 'M': 0.85, ',': 0.95, '.': 1.05, '/': 1.15,

    // Special keys
    'Tab': 0.1,
    'CapsLock': 0.15,
    'Shift': 0.2,
    'Ctrl': 0.05,
    'Alt': 0.25,
    'Space': 0.6,
    'Enter': 1.3,
    'Backspace': 1.3,

    // Function keys
    'F1': 0.15, 'F2': 0.3, 'F3': 0.45, 'F4': 0.6, 'F5': 0.8, 'F6': 0.95, 'F7': 1.1, 'F8': 1.25,
    'F9': 1.4, 'F10': 1.55, 'F11': 1.7, 'F12': 1.85,

    // Navigation keys
    'Insert': 1.4, 'Home': 1.55, 'PageUp': 1.7,
    'Delete': 1.4, 'End': 1.55, 'PageDown': 1.7,

    // Arrow keys
    'ArrowUp': 1.55,
    'ArrowLeft': 1.4, 'ArrowDown': 1.55, 'ArrowRight': 1.7
};

function KeyboardInteraction({ pawRef }) {
    useEffect(() => {
        const addKeyDot = (key) => {
            const position = keyPositions[key.toUpperCase()];
            if (position === undefined) return;

            const keyboardPoints = [
                { x: 988.5, y: 1264.5 },
                { x: 1094.5, y: 1395.5 },
                { x: 1650.5, y: 1359.5 },
                { x: 1728.5, y: 1351.5 },
                { x: 1728.5, y: 1339.61 },
                { x: 1574.5, y: 1223.5 },
                { x: 1073.5, y: 1246.5 },
                { x: 988.5, y: 1255.09 }
            ];

            const originalWidth = 2560;
            const originalHeight = 1440;

            const x = keyboardPoints[0].x + (keyboardPoints[3].x - keyboardPoints[0].x) * position;

            const startY = (keyboardPoints[0].y + keyboardPoints[6].y) / 2;
            const endY = (keyboardPoints[3].y + keyboardPoints[2].y) / 2;
            const slope = (startY - endY) / (keyboardPoints[3].x - keyboardPoints[0].x);

            const verticalOffsetFactor = -1;

            const y = startY + slope * (x - keyboardPoints[0].x) +
                (keyboardPoints[6].y - keyboardPoints[0].y) * verticalOffsetFactor;

            const moveX = (x - originalWidth / 2) * 0.4;
            const moveY = (y - originalHeight / 2) * 0.05;

            if (pawRef.current) {
                const rect = pawRef.current.getBoundingClientRect();
                const scale = rect.width / originalWidth;
                const scaledMoveX = moveX * scale;
                const scaledMoveY = moveY * scale;
                pawRef.current.style.transform = `translate(${scaledMoveX + 50}px, ${scaledMoveY}px)`;
            }
        };

        const handleKeyDown = (event) => {
            const key = event.key;

            if (keyPositions.hasOwnProperty(key.toUpperCase()) ||
                keyPositions.hasOwnProperty(key)) {

                if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Space'].includes(key)) {
                    event.preventDefault();
                }

                addKeyDot(key);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [pawRef]);

    return null;
}

export default KeyboardInteraction;
