// src/Playlist.js
import React from 'react';
import './Playlist.css';

const Playlist = ({ songs, currentSong, onSongSelect }) => {
  return (
    <div className="playlist">
      <h3>Playlist</h3>
      <ul>
        {songs.map((song, index) => (
          <li
            key={index}
            className={currentSong === song ? 'active' : ''}
            onClick={() => onSongSelect(song)}
          >
            {song.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Playlist;