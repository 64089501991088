import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import Playlist from './Playlist';
import './AudioPlayer.css';

const AudioPlayer = forwardRef(({ isLoggedIn }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(0.2);
    const [audioData, setAudioData] = useState(new Uint8Array(0));
    const [currentSong, setCurrentSong] = useState(null);
    const audioRef = useRef(new Audio());
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const sourceRef = useRef(null);
    const progressBarRef = useRef(null);

    const songs = [
        { title: "Evil Dub", src: "/Trentemøller_ Evil Dub.mp3" },
        { title: "Dark Synth", src: "/song_2.mp3" },
        { title: "Star Fighter", src: "/song.mp3" },
        // Add more songs as needed
    ];

    useEffect(() => {
        setCurrentSong(songs[0]);
        audioRef.current.src = songs[0].src;

        // Attach the timeupdate event listener
        const audioElement = audioRef.current;
        audioElement.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        play: () => {
            if (audioRef.current && !isPlaying) {
                initializeAudioContext();
                audioRef.current.volume = volume;
                audioRef.current.play();
                setIsPlaying(true);
            }
        }
    }));

    const initializeAudioContext = () => {
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 256;
        }

        if (sourceRef.current) {
            sourceRef.current.disconnect();
        }

        sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
        sourceRef.current.connect(analyserRef.current);
        analyserRef.current.connect(audioContextRef.current.destination);
    };

    useEffect(() => {
        return () => {
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                audioContextRef.current.close();
            }
        };
    }, []);

    const handleSongSelect = (song) => {
        setCurrentSong(song);
        setIsPlaying(false);
        setProgress(0);

        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
            analyserRef.current = null;
            sourceRef.current = null;
        }

        // Recreate the audio element
        const newAudioElement = new Audio(song.src);
        newAudioElement.volume = volume;
        newAudioElement.addEventListener('timeupdate', handleTimeUpdate);
        newAudioElement.onEnded = () => setIsPlaying(false);
        audioRef.current = newAudioElement;

        setTimeout(() => {
            initializeAudioContext();
            if (audioRef.current) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }, 0);
    };

    const togglePlay = () => {
        if (!audioContextRef.current) {
            initializeAudioContext();
        }
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progress);
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    const handleProgressBarClick = (e) => {
        const progressBar = progressBarRef.current;
        const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
        const newTime = clickPosition * audioRef.current.duration;
        audioRef.current.currentTime = newTime;
        setProgress(clickPosition * 100);
    };

    const getAverageVolume = (data) => {
        const sum = data.reduce((acc, value) => acc + value, 0);
        return sum / data.length;
    };

    const glowIntensity = getAverageVolume(audioData) / 55;

    return (
        <div className={`audio-player-container ${isLoggedIn ? 'visible' : ''}`}>
            <Playlist songs={songs} currentSong={currentSong} onSongSelect={handleSongSelect} />
            <div className="audio-player" style={{ boxShadow: `0 0 ${10 + glowIntensity * 20}px #0f0` }}>
                <div className="player-content">
                    <button className="play-pause" onClick={togglePlay}>
                        {isPlaying ? '❚❚' : '▶'}
                    </button>
                    <div className="progress-bar" ref={progressBarRef} onClick={handleProgressBarClick}>
                        <div className="progress" style={{ width: `${progress}%` }}></div>
                    </div>
                    <div className="time-display">
                        {Math.floor(audioRef.current?.currentTime || 0)}s / {Math.floor(audioRef.current?.duration || 0)}s
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={volume}
                        onChange={handleVolumeChange}
                        className="volume-slider"
                    />
                </div>
            </div>
        </div>
    );
});

export default AudioPlayer;