import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import './Terminal.css'; // Import the CSS file for styling

const Terminal = forwardRef((props, ref) => {
  const [output, setOutput] = useState([]); // Terminal output lines
  const [currentInput, setCurrentInput] = useState(''); // Current user input
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Login state
  const [inputActive, setInputActive] = useState(false); // Initially false to prevent input during boot sequence
  const [isRainbowMode, setIsRainbowMode] = useState(false); // Rainbow mode for 'script' user
  const [bootIndex, setBootIndex] = useState(0); // Index for boot sequence

  const terminalRef = useRef(null); // Reference to the terminal div
  // const homepageRef = useRef(null); // Reference to the homepage div
  const canvasRef = useRef(null); // Reference to the canvas for matrix rain
  const particlesInitialized = useRef(false); // To prevent re-initializing particles
  const handleKeyPressRef = useRef(null); // Ref to store the latest handleKeyPress function


  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio('/keyboard-single-click.mp3');
    audioRef.current.volume = 0.5; // Adjust volume as needed
  }, []);

  const playKeySound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset audio to start
      audioRef.current.play();
    }
  };

  // Boot sequence messages
  const bootSequence = [
    'Booting DevCat OS kernel 0.5.3...',
    'Loading initial ramdisk...',
    'Starting system logger...',
    'Initializing hardware...',
    'Mounting filesystems...',
    'Starting network services...',
    'Starting DevCat Display Manager...',
    'Welcome to DevCat OS 0.5.3',
    '',
    '',
  ];

  // Available commands
  const menuOptions = [
    'Available commands:',
    'homepage - Open the homepage',
    'dexscreener - Open Dexscreener',
    'twitter - Open Twitter/X',
    // 'telegram - Open Telegram',
    'ca - Display Contract Address',
    'ls - List directory contents',
    'cat [filename] - Display file contents',
    'clear - Clear the terminal',
    'exit - Log out',
    'help - Display this help message',
    'stats - Display system information',
  ];

  // Simulated file system
  const fileSystem = {
    'readme.txt':
      'Welcome to DevCat OS!\nThis is a highly sophisticated feline-powered operating system.\nPlease enjoy your stay and remember: curiosity powers the cat!',
    'secret.txt': 'The secret to unlimited catnip is [REDACTED]\n\nPS:  try logging in as script',
    'image.jpg': 'https://i.imgur.com/r7iJhjV.png',
    'stats.txt': ` /\\_/\\  DevCat OS 0.5.3
( o.o )  ----------------
 > ^ <   CPU: Quantum Feline 9000
         RAM: 128 GB DDR5 Catnip-infused
         GPU: NyanForce RTX 4090
         Storage: 10 TB SSD (Scratch-resistant)
         OS: DevCat OS 0.5.3
         Kernel: Furry 5.15.0-generic`,
  };

  // Initialize the boot sequence
  useEffect(() => {
    const bootTimeout = setTimeout(() => setBootIndex(1), 500);
    return () => clearTimeout(bootTimeout);
  }, []);

  // Handle the boot sequence display
  useEffect(() => {
    if (bootIndex > 0 && bootIndex <= bootSequence.length) {
      const bootTimer = setTimeout(() => {
        setOutput((prev) => [...prev, bootSequence[bootIndex - 1]]);
        setBootIndex(bootIndex + 1);
      }, 50);
      return () => clearTimeout(bootTimer);
    } else if (bootIndex > bootSequence.length) {
      setInputActive(true); // Enable input after boot sequence completes
    }
  }, [bootIndex]);

  // Watch for changes to isLoggedIn to display stats after login
  useEffect(() => {
    if (isLoggedIn) {
      displayStats();
    }
  }, [isLoggedIn]);

  // Update the handleKeyPressRef to point to the latest handleKeyPress function
  useEffect(() => {
    handleKeyPressRef.current = handleKeyPress;
  });

  // Add the event listener for key presses once
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (handleKeyPressRef.current) {
        handleKeyPressRef.current(event);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Handle key presses for user input
  const handleKeyPress = (event) => {
    if (!inputActive) return;
    playKeySound(); // Add this line to play the sound
    if (event.key === 'Enter') {
      processCommand(currentInput);
      setCurrentInput('');
    } else if (event.key === 'Backspace') {
      event.preventDefault();
      setCurrentInput((prev) => prev.slice(0, -1));
    } else if (event.key.length === 1) {
      setCurrentInput((prev) => prev + event.key);
    }
  };

  // Get the appropriate prompt based on login state
  const getPrompt = () => {
    if (!isLoggedIn) return 'devcat login: ';
    return isRainbowMode ? 'script@devcat:~$ ' : 'root@devcat:~$ ';
  };

  // Process user commands
  const processCommand = (command) => {
    const trimmedCommand = command.trim();
    const [cmd, ...args] = trimmedCommand.split(' ');

    // Append the prompt and user's command to the output
    setOutput((prev) => [...prev, getPrompt() + command]);

    if (!isLoggedIn) {
      if (trimmedCommand.toLowerCase() === 'root') {
        // Emit a custom event when login is successful
        const loginEvent = new CustomEvent('userLoggedIn');
        window.dispatchEvent(loginEvent);

        setIsLoggedIn(true);
        setOutput((prev) => [
          ...prev,
          'Access granted. Welcome to DevCat OS 0.5.3!',
        ]);
      } else if (trimmedCommand.toLowerCase() === 'script') {
        setIsLoggedIn(true);
        setIsRainbowMode(true);
        if (terminalRef.current) {
          terminalRef.current.classList.add('rainbow-text');
        }
        initParticles();
        setOutput((prev) => [
          ...prev,
          'Access granted. Welcome to DevCat OS 0.5.3 - Rainbow Edition!',
        ]);
      } else {
        setOutput((prev) => [...prev, 'Login incorrect', '']);
      }
      return;
    }

    switch (cmd.toLowerCase()) {
      case 'homepage':
        setOutput((prev) => [...prev, 'coming soon...']);

        break;
      case 'dexscreener':
        window.open('https://dexscreener.com/solana/8titbgl7hkgx4qttxhqlpxwepscjrdw43rnrqmdxdpnt', '_blank');
        setOutput((prev) => [...prev, '']);
        break;
      case 'twitter':
        window.open('https://x.com/DevCatCoin', '_blank');
        setOutput((prev) => [...prev, '']);
        break;
      // case 'telegram':
      //   window.open('https://t.me/', '_blank');
      //   setOutput((prev) => [...prev, '']);
      //   break;
      case 'ca':
        setOutput((prev) => [
          ...prev,
          'Contract Address: 8pJEieMH3gCiXEshamT7bmMVRrcMrd19LxA7neX8pump',
          '',
        ]);
        break;
      case 'ls':
        setOutput((prev) => [...prev, Object.keys(fileSystem).join('  '), '']);
        break;
      case 'cat':
        if (args.length === 0) {
          setOutput((prev) => [...prev, 'Usage: cat [filename]', '']);
        } else {
          const filename = args[0];
          if (fileSystem.hasOwnProperty(filename)) {
            if (filename.match(/\.(jpg|png|gif)$/)) {
              setOutput((prev) => [
                ...prev,
                <img
                  src={fileSystem[filename]}
                  alt={filename}
                  className="terminal-image"
                  key={Date.now()}
                />,
                '',
              ]);
              // Add this setTimeout to scroll after the image is added
              setTimeout(() => scrollToBottom(), 100);
            } else {
              setOutput((prev) => [...prev, fileSystem[filename], '']);
            }
          } else {
            setOutput((prev) => [...prev, `File not found: ${filename}`, '']);
          }
        }
        break;
      case 'clear':
        setOutput([]);
        break;
      case 'exit':
        setOutput((prev) => [...prev, 'Logging out...', 'Goodbye!']);
        setInputActive(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        break;
      case 'help':
        setOutput((prev) => [...prev, ...menuOptions, '']);
        break;
      case 'stats':
        setOutput((prev) => [
          ...prev,
          'Displaying system information...',
          <pre key={Date.now()}>{fileSystem['stats.txt']}</pre>,
          '',
        ]);
        break;
      default:
        setOutput((prev) => [...prev, 'Command not found: ' + command, '']);
    }
  };

  // Display system stats after login
  const displayStats = () => {
    setOutput((prev) => [
      ...prev,
      'Displaying system information...',
      <pre key={Date.now()}>{fileSystem['stats.txt']}</pre>,
      '',
    ]);
  };

  // Scroll terminal to the bottom
  const scrollToBottom = () => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  };
  // Scroll whenever output updates
useEffect(() => {
  scrollToBottom();
}, [output]);

  // Show the homepage with matrix rain effect
  // const showHomepage = () => {
  //   setInputActive(false);
  //   if (homepageRef.current) {
  //     homepageRef.current.style.display = 'block';
  //   }
  //   initMatrixRain();
  // };

  // Initialize the matrix rain effect
  const initMatrixRain = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const columns = canvas.width / 20;
    const drops = [];

    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0F0';
      ctx.font = '15px monospace';

      for (let i = 0; i < drops.length; i++) {
        const text = String.fromCharCode(Math.floor(Math.random() * 128));
        ctx.fillText(text, i * 20, drops[i] * 20);

        if (drops[i] * 20 > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
    };

    const intervalId = setInterval(draw, 33);

    // const closeHomepage = () => {
    //   if (homepageRef.current) {
    //     homepageRef.current.style.display = 'none';
    //   }
    //   clearInterval(intervalId);
    //   setInputActive(true);
    // };

    // const closeButton = document.getElementById('closeHomepage');
    // if (closeButton) {
    //   closeButton.addEventListener('click', closeHomepage);
    // }

    // Cleanup function to remove event listener
    // return () => {
    //   clearInterval(intervalId);
    //   if (closeButton) {
    //     closeButton.removeEventListener('click', closeHomepage);
    //   }
    // };
  };

  // Initialize particles effect for 'script' user
  const initParticles = () => {
    if (!particlesInitialized.current && window.particlesJS) {
      window.particlesJS('particles-js', {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0,
              color: '#000000',
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'repulse',
            },
            onclick: {
              enable: true,
              mode: 'push',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
      particlesInitialized.current = true;
    }
  };

  return (
    <div className="terminal-container">
      <div id="particles-js"></div>
      <img
        id="logo"
        src="https://i.imgur.com/oqGsvkR.png"
        alt="DevCat OS logo, stylized cat face in neon green"
      />
      <img
        id="logo2"
        src="https://i.imgur.com/4dZ3QWO.png"
        alt="DevCat OS secondary logo, stylized cat face in neon green"
      />
      <div id="terminal" ref={terminalRef}>
        {output.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
        {inputActive && (
          <div>
            {getPrompt()}
            {currentInput}
            <span className="cursor">█</span>
          </div>
        )}
      </div>

    </div>
  );
});

export default Terminal;
